<template>
  <CRow class="widgets">

      <CWidgetDropdown 
        class="widget" 
        color="gradient-primary" 
        :header="String(ordersData.completeCount) +'/'+ String(ordersData.count)"
        text="Tamamlanan Siparişler (Son 30 Gün)"
        v-if="checkRole('order/orders')">
        <template #default>
          <div class="loading" v-if="orderLoading"> <CSpinner  color="white"/> </div>
        </template>
        <template #footer>
          <CChartLineSimple
            v-if="!orderLoading"
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="ordersData.completed"
            point-hover-background-color="dark"
            :labels="weeks"
            label="Adet"
          />
        </template>
      </CWidgetDropdown>

      <CWidgetDropdown 
        class="widget"
        color="gradient-danger" 
        :header="String(ordersData.returnedCount) +'/'+ String(ordersData.count)" 
        text="Sipariş Değişimleri (Son 30 Gün)"
        v-if="checkRole('order/orders')">
        <template #default> 
          <div class="loading" v-if="orderLoading"> <CSpinner  color="white"/> </div>
        </template>
        <template #footer>
          <CChartLineSimple
            v-if="!orderLoading"
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="ordersData.returned"
            point-hover-background-color="danger"
            :options="{ elements: { line: { tension: 0.00001 }}}"
            :labels="weeks"
          />
        </template>
      </CWidgetDropdown>

      <CWidgetDropdown
        class="widget"
        color="gradient-dark"
        :header="String(newsletterSubCount)"
        text="Newsletter Aboneleri (Son 30 Gün)"
        v-if="checkRole('social/newsLetterSubs')"
      >
        <template #default> 
          <div class="loading" v-if="socialLoading"> <CSpinner  color="white"/> </div>
        </template>
        <template #footer>
          <CChartBarSimple
            v-if="!socialLoading"
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="newsletterSubsData"
            background-color="#576163"
            label="Abone"
          />
        </template>
      </CWidgetDropdown>

      <CWidgetDropdown
        class="widget"
        color="gradient-info"
        :header="String(newsletterCount)"
        text="Gönderilen Newsletterlar"
        v-if="checkRole('notification/newsletters')"

      >
        <template #default>
          <div class="loading" v-if="notificationLoading"> <CSpinner  color="white"/> </div>
        </template>
        <template #footer>
          <CChartLineSimple
            v-if="!notificationLoading"
            :wrapper="false"
            style="height: 70px"
            label="Newsletter"
            :data-points="newsletterData"
        />
         
        </template>
      </CWidgetDropdown>

      <CWidgetDropdown
        class="widget"
        color="gradient-warning"
        :header="String(stockReportKaft.total)"
        text="Stok Bilgisi (KAFT)"
        v-if="checkRole('report/stock')"

      >
        <template #default>
          <div class="loading" v-if="reportLoading"> <CSpinner  color="white"/> </div>
        </template>
        <template #footer>
          <CChartBarSimple
            v-if="!reportLoading"
            class="mt-3 mx-3"
            style="height: 70px"
            :labels="stockReportKaft.labels"
            :data-points="stockReportKaft.datas"
            label="Adet"
            background-color="white"
          />
        </template>
      </CWidgetDropdown>

      <CWidgetDropdown
        class="widget"
        color="gradient-warning"
        :header="String(stockReportLogistic.total)"
        text="Stok Bilgisi (Lojistik)"
        v-if="checkRole('report/stock')"

      >
        <template #default>
          <div class="loading" v-if="reportLoading"> <CSpinner  color="white"/> </div>
        </template>
        <template #footer>
          <CChartBarSimple
            v-if="!reportLoading"
            class="mt-3 mx-3"
            style="height: 70px"
            :labels="stockReportLogistic.labels"
            :data-points="stockReportLogistic.datas"
            label="Adet"
            background-color="white"
          />
        </template>
      </CWidgetDropdown>
      
  </CRow>
</template>

<script>

import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'
import moment from 'moment'
export default {
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
  props: {
    ordersData: Object,
    userRole: Object,
    checkRole: Function,
    weeks: Array
  },
  computed:{
    orderLoading: function(){
      return this.$store.getters.orderLoading
    },
    productionLoading: function(){
      return this.$store.getters.productionLoading
    },
    socialLoading: function(){
      return this.$store.getters.socialLoading
    },
    notificationLoading: function(){
      return this.$store.getters.notificationLoading
    },
    reportLoading: function(){
      return this.$store.getters.reportLoading
    },
    customerData: function(){
      let data = [0,0,0,0,0,0,0,0,0,0,0,0]
      this.$store.getters.customers.map( item => {
        data[moment(item.createTime).format('M')-1]++
      })
      return data
    },
    customerCount: function(){
      return this.$store.getters.customerCount
    },
    newsletterSubsData(){
      let data = [0,0,0,0,0,0,0,0,0,0,0,0]
      this.$store.getters.newsletterSubs.map(  item => {
        data[moment(item.createTime).format('M')-1]++
      })
      return data
    },
    newsletterSubCount(){
      return this.$store.getters.newsletterSubCount
    },
    newsletterData: function(){
      let data = [0,0,0,0,0,0,0,0,0,0,0,0]
      this.$store.getters.newsletters.map( item => {
         data[moment(item.createTime).format('M')-1]++
      })
      return data
    },
    newsletterCount: function(){
      return this.$store.getters.newsletterCount;
    },

    stockReportKaft: function(){
      let data = {labels: [], datas:[], total: 0};
      this.$store.getters.stockReports.map( item => {
        if(item.kaftAmount > 0){
          data.labels.push(item.ptName)
          data.datas.push(item.kaftAmount)
          data.total = parseInt(data.total) + parseInt(item.kaftAmount)
        }
       
      })
      return data;
    },

    stockReportLogistic: function(){
      let data = {labels: [], datas:[], total: 0};
      this.$store.getters.stockReports.map( item => {
        if(item.logisticsAmount > 0){
          data.labels.push(item.ptName)
          data.datas.push(item.logisticsAmount)
          data.total = parseInt(data.total) + parseInt(item.logisticsAmount)
        }
       
      })
      return data;
    },

    productionInvoices: function(){
      this.$store.commit('productionLoading', true);
      let data = {total:0, data: [0,0,0,0,0,0,0,0,0,0,0,0]}
      this.$store.getters.productionInvoices.map( async (item) => {
         data.data[moment(item.invoiceDate).format('M')-1]+=item.totalAmount
         data.total += item.totalAmount
      })
      this.$store.commit('productionLoading', false);
      return data
    },
    
  }
}
</script>

<style scoped>
.widgets{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
 .widget{
  margin: .5rem;
  width: 325px;
 }
</style>
